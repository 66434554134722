// Required dependencies
import 'jquery';
import 'bootstrap';
// import 'tinymce';

// Assets
// These assets are extracted to [id].bundle.css using MiniCssExtractPlugin, see more on webpack.config.js
// import 'bootstrap/dist/css/bootstrap.css';
import './theme/admin.scss';

// UI tweaks
import './ui/naja';

import './modules/admin';

// Webpack HMR
if (module.hot) {
	module.hot.accept();
  }
