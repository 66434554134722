import naja from "naja";

// We must attach Naja to window load event.
document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));

// https://forum.nette.org/cs/34118-naja-svazani-s-jinym-elementem-nez-odkazem-formularem

naja.uiHandler.addEventListener('interaction', (event) => {
	if (event.detail.element.hasAttribute('data-send')) {

		// console.log(event.detail.element.closest('form'));
		// const forms = document.querySelectorAll('.needs-validation');
		const forms = document.querySelectorAll('form');
		// const forms = event.detail.element.closest('form');

		Array.prototype.slice.call(forms)
			.forEach(function (form) {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}

				form.classList.add('was-validated')
			}, false)
	}
});
